@charset "utf-8";
/* ============================================================ */
/* header */
/* ============================================================ */
.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: 100%;
	height: 80px;
	padding: 0 0 0 30px;
	border-bottom: 1px solid #dbdbdb;
	background: $white;
	
	&__logo {
		flex: 0 1 150px;
	}

	.gnav {
		display: flex;
		
		&__item {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 80px;
			padding: 0 20px;
			color: $textColor;
			text-decoration: none;
			
			@media screen and (min-width:  767.5px) and (max-width:1024px) {
				padding: 0 15px;
			}
			
			&::before {
				position: absolute;
				left: 50%;
				bottom: 0;
				z-index: 5;
				content: "";
				width: 0;
				height: 4px;
				background: $baseColor;
				backface-visibility: hidden;
				transform: translateX(-50%);
				@include transition;
			}
			&:hover::before {
				width: 100%;
			}
			
			&--contact {
				background: $baseColor;
				@include transition;
				
				&:hover {
					background: $textColor;
					color: $white;
					
					&::before {
						width: 0%;
					}
				}
			}
		}
	}
	
	
}


