@charset "utf-8";
/* ============================================================ */
/* .contents */
/* ============================================================ */
.contents {
	max-width: 100%;
	
	.inner {
		&__1000 {
			max-width: 1030px;
			margin: 0 auto;
			padding: 0 15px;
		}
		&__800 {
			max-width: 830px;
			margin: 0 auto;
			padding: 0 15px;
		}
	}
}


/* ============================================================ */
/* 汎用スタイル */
/* ============================================================ */
/* --------------------------------------------------- */
/* テキスト */
/* --------------------------------------------------- */
p {
	margin-bottom: 15px;
	
	&:last-child {
		margin-bottom: 0;
	}
}

.indent {
	display: table;
	
	& > *:first-child {
		display: table-cell;
		white-space: nowrap;
	}
}


/* --------------------------------------------------- */
/* 見出し */
/* --------------------------------------------------- */
.ttl01 {
	font-size: 2.4rem;
	text-align: center;
	line-height: 1.2;
	display: flex;
    flex-direction: column;
	margin: 0 0 40px;
	
	&::after {
		content: attr(data-text);
		color: #9e9400;
		font-size: 1.6rem;
		font-family: 'Quicksand', sans-serif;
		font-weight: 700;
		margin: 10px 0 0;
	}
}

.ttl02 {
	margin: 0 0 20px;
	color: $textColor;
	font-size: 2.2rem;
	line-height: 1.3;
	
	&::after {
		content: "";
		width: 80px;
		height: 3px;
		border-bottom: 3px solid #eaf000;
		display: block;
		margin: 20px 0 0;
	}
	
}

.ttl03 {
	margin: 0 0 15px;
	font-size: 1.6rem;
	font-weight: 500;
	
	&::before {
		content: "";
		width: 15px;
		height: 15px;
		display: inline-block;
		background: $baseColor;
		margin: 0 10px 0 0;
	}
}


/* --------------------------------------------------- */
/* カラム制御 */
/* --------------------------------------------------- */

/* 複数カラム
/* --------------------------------------------------- */
.column {
	display: flex;
	flex-wrap: wrap;
}

.column > * {
	margin-bottom: 15px;

	* {
		max-width: 100%;
	}

	img {
		outline: 1px solid transparent;
		
		&:not([class*="wd-a"]) {
			width: 100%;
		}
	}
}

.column1 {
	&> * {
		width: 100%;
	}
}

.column2 {
	 margin-left: -5px;
	 margin-right: -5px;

	 &> * {
		 margin-left: 5px;
		 margin-right: 5px;
		 width: calc((100% - 21px) / 2 );
	 }
 }

.column3 {
	margin-left: -10px;
	margin-right: -10px;

	&> * {
		margin-left: 10px;
		margin-right: 10px;
		width: calc((100% - 61px) / 3);
	}
}


/* --------------------------------------------------- */
/* リスト */
/* --------------------------------------------------- */
.list {
	list-style-type: none;

	& > * {
		position: relative;
		padding-left: 15px;
		margin-bottom: 5px;

		&::before {
			content: "";
			width: 6px;
			height: 6px;
			display: block;
			position: absolute;
			top: 0.7em;
			left: 0;
		}
	}
	&--circle {
		& > * {
			&::before {
				width: 6px;
				height: 6px;
				border-radius: 100%;
			}
		}
	}
	&--black {
		& > * {
			&::before {
				background: $textColor;
			}
		}
	}
	&--decimal {
		list-style: decimal outside;
		margin: 0 0 15px 20px;
		& > * {
			padding-left: 0;
		}
	}
}


/* --------------------------------------------------- */
/* box */
/* --------------------------------------------------- */
.box {
	padding: 25px;
	box-sizing: border-box;
	background: $white;

	&--gray {
		background: #f9f9f9;
	}

	&--border-gray {
		border: 1px solid $borderColor;
	}
	
	&--red {
		background: #fde9e9;
		border: 1px solid #f8cfd4;
	}
}


/* --------------------------------------------------- */
/* table */
/* --------------------------------------------------- */
.table {
	width: 100%;
	
	th,
	td {
		padding: 10px 15px;
		font-size: $s;
		text-align: left;
		vertical-align: middle;
		line-height: 1.6;
	}
}

.table--center {
	th,
	td {
		text-align: center;
	}
}

.table--thin {
	th,
	td {
		padding: 5px 10px;
		line-height: 1.4;
	}
}


/* --------------------------------------------------- */
/* btn */
/* --------------------------------------------------- */
.btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 320px;
	height: 65px;
	margin: 0 5px;
	padding: 0 30px;
	font-size: $m;
	text-align: center;
	text-decoration: none;
	line-height: 1.4;
	cursor: pointer;
	box-shadow: 0px 2px 0 rgba(0, 0, 0, .3);
	transition: 0.3s all;
	@include radius(35);
	transition: 0.2s all ease 0s;

	.icon-fix-left,
	.icon-fix-right {
		@include centering-elements(false, true);
	}
	.icon-fix-left { left: 15px; }
	.icon-fix-right { right: 15px; }
	
	&:hover {
		text-decoration: none;
		box-shadow: none;
		transform: translate3d(0, 2px, 0);
	}
	
	&--yellow {
		background: #eaf000;
		color: $textColor;
	}
	&--black {
		background: $textColor;
		color: $white;
	}
}

button,
input[type="button"].btn,
input[type="submit"].btn {
	appearance: none;
}


/* --------------------------------------------------- */
/* form */
/* --------------------------------------------------- */
.contact-label {
	&__en {
		display: block;
		font-family: 'Quicksand', sans-serif;
		font-weight: 700;
		font-size: 1.3rem;
		color: #9e9400;
	}
}

.error-block {
	margin-top: 5px;
	font-weight: bold;
}

input[type="text"] {
	appearance: none;
	height: 40px;
	padding: 0 10px;
	color: $textColor;
	font-size: $m;
	background: #e6e6d9;
	@include radius(5);
}

textarea {
	appearance: none;
	padding: 5px 10px;
	width: 100%;
	height: 200px;
	background: #e6e6d9;
	color: $textColor;
	@include radius(5);
}

select {
	height: 40px;
	padding: 0 0 0 10px;
	background: $white;
	border: 1px solid #d2d2d2;
}

input[type="text"]:focus,
textarea:focus {
  outline: 0;
  border: 1px solid #ccc;
	background: $white;
}

table {
	.error {
		color: #dd0000;
		
		input[type="text"],
		textarea {
			background: #fde9e9;
			border: 1px solid #f8cfd4;
		}
	}	
}
