@charset "utf-8";

/* ============================================================ */
/* メインビジュアル */
/* ============================================================ */
.visual {
	position: relative;
	
	&__inner {
		display: flex;
		align-items: flex-start;
		justify-content: flex-end;
		position: relative;
		margin: 0 0 20px;
	}

	&__text {
		position: absolute;
		left: 0;
		top: 25px;
		z-index: 4;
	}
	
	&__catch {
		border-left: 8px solid $baseColor;
		padding: 10px 0 15px 15px;
		text-shadow: 0px 0px 5px $white,0px 0px 10px $white,0px 0px 15px $white,0px 0px 20px $white,0px 0px 25px $white;

		&__ttl {
			font-size: 3.0rem;
			line-height: 1.35;
			margin: 0 0 12px;
		}
		&__sub {
			font-size: $s;
			line-height: 1.5;
		}
	}
	&__human {
		width: 60%;
		margin: 15px 0 0 25px;
	}
	
	&__pic {
		width: 70%;
		height: 250px;
		z-index: 1;
		position: relative;
		background: url(/common/img/index/pic_visual.png) center center no-repeat;
		background-size: cover;

		img {
			display: none;
		}
	}
	
	&__scroll {
		display: none;
	}
}


/* ============================================================ */
/* 事業内容 */
/* ============================================================ */
.business {
	padding: 40px 15px;

	&__catch {
		font-size: 2.6rem;
		line-height: 1.4;
		font-weight: 500;
		background: linear-gradient(135deg,  #f1f54e 0%,#f2f582 100%);
		box-shadow:0px 0px 15px 0px rgba(0,0,0,0.05);
		max-width: 900px;
		margin: 0 auto -25px;
		padding: 30px 0;
		text-align: center;
		z-index: 1;
		position: relative;
	}

	&__block {
		&__pic {
			margin: 0 -15px 25px;
		}
	}
}


/* ============================================================ */
/* 会社概要 */
/* ============================================================ */
.company {
	padding: 40px 0 30px;
	background: rgba(191, 191, 118, 0.1);

	&__inner {
		padding: 0 15px;
	}

	&__profile {
		&__item {
			display: flex;
			justify-content: flex-start;
			padding: 20px 15px;

			&:nth-child(odd) {
				background: $white;
			}

			&__ttl {
				width: 90px;
				font-weight: 500;
				border-right: 2px solid $baseColor;
				margin: 0 15px 0 0;

				& + dd {
					width: calc(100% - 100px);
				}
			}
		}
	}
}


/* ============================================================ */
/* お問い合わせ */
/* ============================================================ */
.contact {
	padding: 40px 15px;
	background: url(/common/img/index/bg_contact.png) center bottom no-repeat rgba(225, 248, 251, 0.35);
	background-size: 300% auto;

	&__box {
		width: 100%;
		margin: 0 auto;
		padding: 25px 15px;
		background: $white;
		box-shadow:0px 0px 15px 0px rgba(0,0,0,0.05);
	}
	
	.table {
		th,td {
			padding-left: 0;
			padding-right: 0;
		}
	}
}


/* ============================================================ */
/* ご利用案内 */
/* ============================================================ */
.guide {
	padding: 40px 0;

	&__inner {
		padding: 0 15px;
	}

	&__block {
		&__item:first-of-type {
			margin: 0 0 20px;
		}
	}
}
	

/* ============================================================ */
/* 送信内容確認 */
/* ============================================================ */
.contact_confirm {
	padding: 40px 15px;
	
	.table {
		tr:nth-child(odd) {
			background: rgba(191, 191, 118, 0.1);
		}
	}
}


/* ============================================================ */
/* 送信完了画面 */
/* ============================================================ */
#contact_complete {
	min-height: 100%;
	position: relative;
	
	.wrap {
		height: 100%;
		padding-bottom: 170px;
	}
	.footer {
		position: absolute;
		bottom: 0;
		width: 100%;
	}
}

.contact_complete {
	padding: 40px 15px;
}


/* ============================================================ */
/* エラーページ */
/* ============================================================ */
.error {
	min-height: 100%;
	position: relative;
	
	.wrap {
		height: 100%;
		padding-bottom: 170px;
	}
	
	.contents {
		padding: 40px 15px;
	}
	
	.footer {
		position: absolute;
		bottom: 0;
		width: 100%;
	}
}
