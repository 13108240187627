@charset "utf-8";
/* ============================================================ */
/* header */
/* ============================================================ */
.header {
	display: flex;
    justify-content: flex-start;
    align-items: center;
	height: 55px;
	border-bottom: 1px solid #dbdbdb;
	padding: 0 15px;
	background: $white;
	
	&__logo {
		flex: 1 1 auto;
		line-height: 0;
		
		img {
			width: 100px !important;
		}
	}
	
	&__menu,
	&__menu span {
		@include transition;
	}

	&__menu {
		position: relative;
		width: 28px;
		height: 22px;
		z-index: 11;
		cursor: pointer;

		&__item {
			display: inline-block;
			position: absolute;
			left: 0;
			width: 100%;
			height: 2px;
			background: $baseColor;
			@include radius(2);

			&:nth-of-type(1) { top: 0; }
			&:nth-of-type(2) { top: 10px; }
			&:nth-of-type(3) { bottom: 0; }
		}
		&.active {
			.header__menu__item {
				&:nth-of-type(1) { transform: translateY(10px) rotate(-315deg); }
				&:nth-of-type(2) { opacity: 0; }
				&:nth-of-type(3) { transform: translateY(-10px) rotate(315deg); } 
			}
		}
	}

	.gnav {
		position: absolute;
		left: 0;
		top: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
		padding: 80px 15px 15px;
		background: rgba(#000, 0.85);
		opacity: 0;
		@include transition;

		&__item {
			display: flex;
			align-items: center;
			padding: 15px 10px;
			border-bottom: 1px solid rgba($white, 0.2);
			color: $white;
			font-size: $m;
			text-decoration: none;
			
			&:first-child {
				border-top: 1px solid rgba($white, 0.2);
			}
			.icon-angle-right {
				margin: 0 10px 0 0;
				color: $baseColor;
			}
		}
		
		.show & {
			z-index: 10;
			opacity: 1;
		}
	}
}

