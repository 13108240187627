@charset "utf-8";
/* ============================================================ */
/* footer */
/* ============================================================ */
.footer {
	border-top: 1px solid $borderColor;
	line-height: 1.6;
	padding: 30px;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;

	&__logo {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		
		&__pic {
			display: block;
			width: 150px;
			margin: 0 25px 0 0;
		}
		&__address {
			font-size: $xxs;
		}
	}

	.copyright {
		font-size: $xxs;
		font-family: 'Quicksand', sans-serif;
		font-weight: 400;
	}
}