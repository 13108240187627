@charset "utf-8";
/* ============================================================ */
/* footer */
/* ============================================================ */
.footer {
	border-top: 1px solid $borderColor;
	line-height: 1.6;
	padding: 25px 15px 15px;
	text-align: center;

	&__logo {
		&__pic {
			display: block;
			width: 120px;
			margin: 0 auto 10px;
		}
		&__address {
			font-size: $xxs;
			margin: 0 0 15px
		}
	}

	.copyright {
		font-size: $xxxs;
		font-family: 'Quicksand', sans-serif;
		font-weight: 400;
	}
}