@charset "utf-8";
/* ============================================================ */
/* 基本設定 */
/* ============================================================ */
html {
	overflow-y: scroll;
}

.wrap {
	color: $textColor;
	font-size: $s;
	line-height: 1.8;
	background: url(/common/img/common/bg_wrap.png) center top repeat;
	font-family: 'Noto Sans JP', sans-serif;
}

.sp-view {
	display: none !important;
}

a {
	color: $baseColor;
	text-decoration: underline;
	
	&:hover {
		text-decoration: none;
	}
}


/* --------------------------------------------------- */
/* pagetop */
/* --------------------------------------------------- */
.pagetop {
	position: fixed;
	right: 50px;
	bottom: 50px;
	
	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50px;
		height: 50px;
		background: rgba($baseColor, 0.9);
		color: $white;
		font-size: $xxxl;
		text-decoration: none;
		@include radius(4);
		@include transition;
		
		&:hover {
			@include opacity;
		}
	}
}