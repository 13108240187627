@charset "utf-8";

/* ============================================================ */
/* メインビジュアル */
/* ============================================================ */
.visual {
	position: relative;
	
	&__inner {
		display: flex;
		align-items: flex-start;
		justify-content: flex-end;
		position: relative;
	}

	&__text {
		position: absolute;
		left: 0;
		top: calc(50% - 35%);
		z-index: 10;
	}
	
	&__catch {
		border-left: 15px solid $baseColor;
		padding: 0 0 15px 65px;

		&__ttl {
			font-size: 7.6rem;
			line-height: 1.3;
			margin: 0 0 20px;
		}
		&__sub {
			font-size: 3.0rem;
		}
	}
	&__human {
		width: 50vw;
		max-width: 718px;
		margin: 65px 0 0 35px;
	}
	
	&__pic {
		width: 73.5%;
		z-index: 1;
		position: relative;

		img {
			max-width: 100%;
		}
	}
	
	&__scroll {
		height: 90px;
		padding: 55px 0 0 25px;
		border-left: 2px solid $baseColor;
		font-size: $xxxs;
		font-family: 'Quicksand', sans-serif;
		font-weight: 400;
		display: flex;
		align-content: center;
		justify-content: flex-start;
		position: absolute;
		left: 50%;
		bottom: -60px;
		z-index: 8;
	}
	
	@media screen and (min-width:  1001px) and (max-width:1200px) {
		.visual {
			&__text {
				top: calc(50% - 38%);
			}
			&__catch {
				padding: 0 0 15px 40px;

				&__ttl {
					font-size: calc(100vw / 15.6);
				}
				&__sub {
					font-size: calc(100vw / 39.6);
				}
			}
			&__human {
				margin: 30px 0 0 30px;
			}
		}
	}
	@media screen and (min-width:  767.5px) and (max-width:1000px) {
		.visual {
			&__text {
				top: calc(50% - 42%);
			}
			&__catch {
				padding: 0 0 15px 40px;

				&__ttl {
					font-size: calc(100vw / 17.6);
				}
				&__sub {
					font-size: calc(100vw / 42.6);
				}
			}
			&__human {
				margin: 15px 0 0 30px;
			}
		}
	}
}


/* ============================================================ */
/* 事業内容 */
/* ============================================================ */
.business {
	padding: 130px 0 90px;

	&__catch {
		font-size: 4.1rem;
		font-weight: 500;
		background: linear-gradient(135deg,  #f1f54e 0%,#f2f582 100%);
		box-shadow:0px 0px 15px 0px rgba(0,0,0,0.05);
		max-width: 900px;
		margin: 0 auto -40px;
		padding: 55px 0;
		text-align: center;
		z-index: 1;
		position: relative;
	}

	&__block {
		display: flex;
		padding: 0 30px;

		&__pic {
			width: 45%;
			margin: 0 70px 0 0;
		}

		&__text {
			width: calc((100% - 45%) - 70px);
			max-width: 475px;
			margin: 100px 0 0;
		}
	}
}


/* ============================================================ */
/* 会社概要 */
/* ============================================================ */
.company {
	padding: 80px 10px 60px;
	background: rgba(191, 191, 118, 0.1);

	&__inner {
		max-width: 1000px;
		margin: 0 auto;
	}

	&__profile {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		&__block {
			width: 50%;
		}

		&__item {
			display: flex;
			justify-content: flex-start;
			padding: 25px 20px;

			&:nth-child(odd) {
				background: $white;
			}

			&__ttl {
				width: 110px;
				font-weight: 500;
				border-right: 2px solid $baseColor;
				margin: 0 20px 0 0;
			}
		}
	}
}


/* ============================================================ */
/* お問い合わせ */
/* ============================================================ */
.contact {
	padding: 100px 10px;
	background: url(/common/img/index/bg_contact.png) center bottom no-repeat;
	background-size: 100% auto;

	&__box {
		max-width: 1000px;
		margin: 0 auto;
		padding: 60px 80px;
		background: $white;
		box-shadow:0px 0px 15px 0px rgba(0,0,0,0.05);
	}
}


/* ============================================================ */
/* ご利用案内 */
/* ============================================================ */
.guide {
	padding: 80px 0;

	&__inner {
		max-width: 1000px;
		margin: 0 auto;
	}

	&__block {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		border-top: 1px dotted #e5e5d4;
		margin: 30px 0 0;
		padding: 30px 0 0;

		&__item {
			width: calc(50% - 4.5%);
		}
	}
}
	

/* ============================================================ */
/* 送信内容確認画面 */
/* ============================================================ */
.contact_confirm {
	padding: 80px 0;
	
	.table {
		tr:nth-child(odd) {
			background: rgba(191, 191, 118, 0.1);
		}
		th {
			width: 160px;
		}
		td {
			width: calc(100% - 160px);
		}
	}
	.contact-label {
		width: 160px;
	}
}


/* ============================================================ */
/* 送信完了画面 */
/* ============================================================ */
#contact_complete {
	min-height: 100%;
	position: relative;
	
	.wrap {
		height: 100%;
		padding-bottom: 110px;
	}
	.footer {
		position: absolute;
		bottom: 0;
		width: 100%;
	}
}
.contact_complete {
	padding: 80px 0;
}


/* ============================================================ */
/* エラーページ */
/* ============================================================ */
.error {
	min-height: 100%;
	position: relative;
	
	.wrap {
		height: 100%;
		padding-bottom: 110px;
	}
	
	.contents {
		padding: 80px 0;
	}
	
	.footer {
		position: absolute;
		bottom: 0;
		width: 100%;
	}
}


